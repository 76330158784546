<template>
    <div>
        <kendo-grid ref="grid"
                    :data-source="dataSource"
                    :filterable="filterableConfig"
                    :sortable="true"
                    :pageable="pageableConfig"
                    :columns="columns"
                    :resizable="true"
                    >
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import assetService from '../Script/AssetService.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable';
import { globalfunc } from '../../../../shared/GlobalFunction';

export default {
    name: 'AssetGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick'],
    data(){
        return{
            dataSource: new kendo.data.DataSource({
                        page: 1,
                        pageSize: 2,
                        serverPaging: true,
                        serverFiltering: true,
                        serverSorting: true,
                        transport: {
                            read: {
                                contentType: "application/json",
                                url: apiBaseUrl,
                                type: "POST",
                                data: function(e) {
                                    const paging = {
                                        Page : {Limit: e.pageSize,Page: e.page},
                                        Filter : globalfunc.filterQueryBuilder(e.filter),
                                        OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                                    }
                                    const variables = {
                                        paging : paging
                                    }
                                    return { query: assetService.readQuery(),
                                        variables : variables };
                                },
                                beforeSend: function (req) {
                                    req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                    req.setRequestHeader('Path', window.location.pathname);
                                }
                            },
                            parameterMap: function(options, operation) {
                                return  kendo.stringify({
                                    query: options.query,
                                    variables: options.variables
                                });
                            },
                            cache: false
                        },
                        schema: {
                            data: function(response) {
                                if (response.data.GetMasterAsset.master_asset == null)
                                    return [];
                                else
                                    return response.data.GetMasterAsset.master_asset;
                            },
                            total: function(response) {
                                if (response.data.GetMasterAsset.master_asset == null)
                                    return 0;
                                else
                                    return response.data.GetMasterAsset.total;
                            },
                            model: {
                                fields: {
                                    asset_purchase_date: { type: "date" }
                                }
                            }
                        },
                }),
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "asset_status_name", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                { field: "asset_type_name", title: "Type Aktifa", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "asset_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "asset_purchase_date", title: "Tanggal", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: {"class": "k-text-center"},
                    template: "#= kendo.toString(kendo.parseDate(asset_purchase_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #"
                },
                { field: "asset_purchase_value", title: "Harga Pembelian", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "asset_value", title: "Nilai Aktifa", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "is_depreciable", title: "Depresiasi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template: "#= is_depreciable ? 'Yes' : 'No' #" },
                { field: "depreciation_period", title: "Periode Depresiasi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "depreciation_percentage_yearly", title: "Persentase Depresiasi Tahunan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "yearly_depreciation", title: "Depresiasi Tahunan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "all_time_depreciation", title: "Total Depresiasi Tahunan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    mounted: function(){
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(dataItem, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.asset_id);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Aktifa")
        }
    }
}
</script>

<style scoped>
</style>