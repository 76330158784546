import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class AssetService {
    readQuery(){
        var query = `query($paging:ServerPaging){
            GetMasterAsset(Paging:$paging){
                master_asset{
                    asset_id
                    asset_type_id
                    asset_type_name
                    asset_status_id
                    asset_status_name
                    asset_name
                    asset_purchase_date
                    asset_purchase_value
                    asset_value
                    is_depreciable
                    depreciation_period
                    depreciation_percentage_yearly
                    yearly_depreciation
                    all_time_depreciation
                }
                total
            }
        }`;
        return query;
    }

    async deleteQuery(variables){
        const query = gql`mutation($id:Int!){
            DeleteMasterAsset(AssetID:$id)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQuery(variables){
        const query = gql`mutation($data:NewMasterAsset!){
            CreateMasterAsset(New:$data)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        const query = gql`mutation($id:Int!,$data:NewMasterAsset!){
            UpdateMasterAsset(AssetID:$id,New:$data)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new AssetService();