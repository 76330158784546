<template>
    <div>
        <kendo-grid ref="grid"
                    :data-source="dataSource"
                    :pageable="true"
                    :filterable="true"
                    :sortable="true"
                    :columns=columns
                    :resizable="true"
                    >
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import assetDepreciationService from '../Script/AssetDepreciationServices';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'AssetDepreciationGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick'],
    data(){
        return{
            dataSource: new kendo.data.DataSource({
                        page: 1,
                        pageSize: kendo_grid.default_grid_pagesize,
                        serverPaging: false,
                        transport: {
                            read: {
                                contentType: "application/json",
                                url: apiBaseUrl,
                                type: "POST",
                                data: function() {
                                    return { query: assetDepreciationService.readQuery() };
                                },
                                beforeSend: function (req) {
                                    req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                    req.setRequestHeader('Path', window.location.pathname);
                                }
                            },
                            parameterMap: function(options, operation) {
                                return  kendo.stringify({
                                    query: options.query,
                                    variables: options.variables
                                });
                            }
                        },
                        schema: {
                            data: function(response) {
                                if (response.data.GetAssetDepreciation == null)
                                    return [];
                                else
                                    return response.data.GetAssetDepreciation;
                            },
                            total: function(response) {
                                if (response.data.GetAssetDepreciation == null)
                                    return 0;
                                else
                                    return response.data.GetAssetDepreciation.length;
                            }
                        },
                }),
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                { field: "asset_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "asset_depreciation_date", title: "Tanggal Depresiasi Aktifa", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: {"class": "k-text-center"},
                    template: "#= kendo.toString(kendo.parseDate(asset_depreciation_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #"
                },
                { field: "asset_depreciation_percentage", title: "Persentase Depresiasi Aktifa", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "asset_depreciation_value", title: "Nilai Depresiasi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "notes", title: "Note", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: {"class": "k-text-center"},
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'dd-MM-yyyy') #"
                },
            ],
        }
    },
    mounted: function(){
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(dataItem, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Aktifa")
        }
    }
}
</script>

<style scoped>
</style>