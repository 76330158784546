<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Neraca</h2>
            </CCardHeader>
            <CCardBody>
                <div class="col-xs-6 text-light pb-2">
                    <button id="pdf" class="btn btn-secondary"                   
                    @click="pdfClick()">
                        <i class="fa fa-file-pdf-o"></i> PDF
                    </button>&nbsp;
                    <button type="button" class= "btn btn-primary" v-on:click="exportExcel">
                        <i class="fa fa-file-excel-o"></i> Excel
                    </button>
                </div>

                <kendo-grid
                    ref="grid" 
                    :data-source="Balance"
                    :filterable="filterableConfig"
                    :sortable="true"
                    :pageable="pageableConfig"
                    :columns="columns"
                    :resizable="true">
                </kendo-grid>

                <balance-pdf ref="pdf" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import trialBalanceServices from '../../TrialBalance/Script/TrialBalanceServices';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import balancePDF from '../../../../assets/template/BalancePdfTemplate.vue';
import moment from 'moment';
import { kendo_grid } from '../../../../infrastructure/constant/variable';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable.js';

export default {
    name: 'BalanceGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'balance-pdf' : balancePDF,
    },
    async mounted () {
    },
    computed: {
        Balance () {
            var startDate = this.DateFrom;
            var endDate = this.DateTo;
            var setting = this.Setting;

            var variablesNew = {
                                    "startDate" : startDate,
                                    "endDate" : endDate,
                                    "setting" : setting
                                }

            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            return { 
                                query: trialBalanceServices.readBalanceQuery(),
                                variables : variablesNew
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetBalanceUnnested == null){
                            return [];
                        }else{
                            return response.data.GetBalanceUnnested;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetBalanceUnnested == null){
                            return 0;
                        }else{
                            return response.data.GetBalanceUnnested.length;
                        }
                    }
                },
                group: [{ field: "head_name"}],
            })
        }
    },
    data(){
        return{
            DateFrom: this.$route.params.dateFrom,
            DateTo: this.$route.params.dateTo,
            Setting: this.$route.params.setting,
            
            columns:  [
                { field: "head_name", title: "Judul", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "group_name", title: "Grup Akun", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "balance", title: "Saldo", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"},
                    template: "#= kendo.toString(balance, 'n') #" },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator,
            },
            pageableConfig: kendo_grid.default_pageable_config,
        }
    },
    methods: {
        pdfClick() {
            var startDate = this.DateFrom,
                endDate = this.DateTo,
                setting = this.Setting;

            this.$swal(sweet_alert.print).then((result) => {
                if (result.isConfirmed == true) {
                    this.$refs.pdf.generatePDF(startDate, endDate, setting);
                }else if (result.isDenied) {
                    this.$router.push({ name: 'Balance Html Template', params: { dateFrom: startDate , dateTo: endDate, setting: setting } });
                    // const routeData = this.$router.resolve({name: 'Balance Html Template', params: { startdate:startDate, enddate:endDate, setting:setting }});
                    // window.open(routeData.href, '_blank');
                }
            });
        },
        async exportExcel () {
            var variables = {
                startDate : this.DateFrom,
                endDate : this.DateTo,
                setting : this.Setting
            };
            var gridData = this.$refs.grid.kendoWidget().dataSource._data;
            var data = trialBalanceServices.balanceExcel(gridData, variables);
            
            var XLSX = require("xlsx");
            var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

            var filename = 'Neraca_' + dateNow + '.xlsx';
            var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
            ws['!cols'] = trialBalanceServices.balanceExcelHeaderSize();
            
            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        },
    }
}
</script>

<style scoped>
</style>