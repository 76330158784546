import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class AssetDepreciationService {
    readQuery(){
        var query = `query{
            GetAssetDepreciation{
                asset_depreciation_id
                asset_depreciation_date
                asset_id
                asset_name
                status
                asset_depreciation_percentage
                asset_depreciation_value
                notes
                created_at
            }
        }`;
        return query;
    }

    async deleteQuery(variables){
        const query = gql`mutation($id:Int!){
            DeleteAssetDepreciation(AssetDepreciationID:$id)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQuery(variables){
        const query = gql`mutation($data:NewAssetDepreciation!){
            CreateAssetDepreciation(New:$data)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        const query = gql`mutation($id:Int!,$data:NewAssetDepreciation!){
            UpdateAssetDepreciation(AssetDepreciationID:$id,New:$data)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async getAssetQuery(){
        var query = gql`query{
            GetMasterAsset{
                master_asset {
                    asset_id
                    asset_name
                }
                total
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        
        var arrayData = [];
        if(result.data.GetMasterAsset.total > 0){
            for (let i = 0; i < result.data.GetMasterAsset.total; i++) {
                var str = { 
                    value: result.data.GetMasterAsset.master_asset[i].asset_id, 
                    label: result.data.GetMasterAsset.master_asset[i].asset_name
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }   

    async getAssetDetailQuery(id){
        const variables = {
            id : id
        }
        var query = gql`query($id: Int){
            GetMasterAsset(AssetID: $id){
                asset_value
                asset_purchase_value
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);

        return result.data.GetMasterAsset[0];
    }   
}

export default new AssetDepreciationService();