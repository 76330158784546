<template>
    <div>
        <div class="modal fade" id="assetDepreciationModal" tabindex="-1" aria-labelledby="assetDepreciationModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="assetDepreciationModalLabel" class="font-weight-bold">{{ModalTitle}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Aktifa</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <v-select id="Asset" class="pb-3" :options="AssetData" v-model="Asset" @input="onChangeAktiva()" :disabled="DisableAsset"/>
                                    <label id="errorAsset" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold">Harga Pembelian</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="AssetValue" type="number" v-model="AssetValue" class="font-weight-bold" readonly/>
                                    <label id="errorAssetValue" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow >
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Tanggal Depresiasi Aktifa</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <date-picker
                                        v-model="DepreciationAssetDate"
                                        format="DD MMM YYYY"
                                        type="date"
                                        placeholder="Select date"
                                        value-type="YYYY-MM-DD"
                                        style="width:100%"
                                    ></date-picker>
                                    <label id="errorDepreciationAssetDate" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow class="pt-3">
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Persentase Depresiasi Aktifa</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="DepreciationPercentage" type="number" v-model="DepreciationPercentage" class="font-weight-bold" @change="onChangeDepreciationPercentage()"/>
                                    <label id="errorDepreciationPercentage" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Nilai Depresiasi</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="DepreciationValue" type="number" v-model="DepreciationValue" class="font-weight-bold" @change="onChangeDepreciationValue()"/>
                                    <label id="errorDepreciationValue" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Note</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <textarea class="form-control" rows="2" v-model="Note"></textarea>
                                    <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import assetDepreciationService from '../Script/AssetDepreciationServices.js';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { response } from '../../../../infrastructure/constant/response';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name: 'AssetDepreciationForm',
    props: ['reload'],  
    components: {
        'date-picker' : datePicker,
    },
    data: function () {
        return {
            ModalTitle: '',
            AssetDepreciationId: '',
            AssetData: [],
            Asset: '',
            Status: '',
            StatusData: [],
            DepreciationAssetDate: '',
            DepreciationPercentage: 0,
            DepreciationValue: 0,
            AssetValue: 0,
            Note: '',
            Error: 0,
            SaveType: '',
            DisableAsset: true,
        }
    },
    methods: {
        async onChangeAktiva(){
            if(this.Asset != null){
                var assetData = await assetDepreciationService.getAssetDetailQuery(this.Asset.value);
                this.AssetValue = assetData.asset_purchase_value;
            }else{
                this.AssetValue = 0;
            }
            
            this.DepreciationPercentage = 0;
            this.DepreciationValue = 0;
        },
        onChangeDepreciationPercentage(){
            if(this.DepreciationPercentage > 100 || this.DepreciationPercentage < 0){
                this.DepreciationPercentage = 0;
                this.$swal("Error", "Persentase Depresiasi Aktifa tidak dapat melebihi 100 atau dibawah 0", "error");
            }

            this.DepreciationValue = this.AssetValue * (this.DepreciationPercentage / 100);
        },
        onChangeDepreciationValue(){
            if(this.DepreciationValue > this.AssetValue){
                this.$swal("Error", "Nilai Depresiasi tidak dapat melebihi Nilai Aktifa", "error");
                this.DepreciationValue = 0;
            }
            if(this.DepreciationValue < 0){
                this.$swal("Error", "Nilai Depresiasi tidak dapat dibawah 0", "error");
                this.DepreciationValue = 0;
            }
            var depreciationPercentage = (this.DepreciationValue * 100) / this.AssetValue;
            this.DepreciationPercentage = depreciationPercentage == Infinity ? 0 : depreciationPercentage;
        },
        async addClick(){  
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Add Depresiasi Aktifa';       

            this.AssetDepreciationId = '';
            this.AssetData = await assetDepreciationService.getAssetQuery();
            this.Asset = '';
            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';
            this.DepreciationAssetDate = '';
            this.DepreciationPercentage = 0;
            this.DepreciationValue = 0;
            this.Note = '';

            this.AssetValue = 0;
            this.DisableAsset = false;

            this.SaveType = 'Add';
            window.$('#assetDepreciationModal').modal('show');
        },
        async editClick(data, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            
            this.ModalTitle = 'Edit Depresiasi Aktifa';

            this.AssetDepreciationId = data.asset_depreciation_id;
            this.AssetData = await assetDepreciationService.getAssetQuery();
            this.Asset = this.AssetData.find(c => c.value == data.asset_id);
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_depreciation');
            this.Status = data.status;
            this.DepreciationAssetDate = data.asset_depreciation_date;
            this.DepreciationPercentage = data.asset_depreciation_percentage;
            this.DepreciationValue = data.asset_depreciation_value;
            this.Note = data.notes;

            var assetData = await assetDepreciationService.getAssetDetailQuery(this.Asset.value);
            this.AssetValue = assetData.asset_purchase_value;

            this.SaveType = 'Edit';

            this.DisableAsset = true;
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Asset: ';
            }

            window.$('#assetDepreciationModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");
            
            if(this.Asset == null || this.Asset == ''){
                this.errorShow('errorAsset');
            }
            if(this.DepreciationAssetDate == null || this.DepreciationAssetDate.toString() == ''){
                this.errorShow('errorDepreciationAssetDate');
            }
            if(this.DepreciationPercentage == null || this.DepreciationPercentage.toString() == '' || this.DepreciationPercentage <= 0){
                this.errorShow('errorDepreciationPercentage');
            }
            if(this.DepreciationValue == null || this.DepreciationValue.toString() == '' || this.DepreciationValue <= 0){
                this.errorShow('errorDepreciationValue');
            }
        },
        saveClick() {
            this.inputValidation();
            
            if(this.Error == 0){

                const assetDepreciationData = {
                    asset_depreciation_date: this.DepreciationAssetDate,
                    asset_id: this.Asset.value,
                    status: this.Status,
                    asset_name: this.Asset.label,
                    asset_depreciation_percentage: parseFloat(this.DepreciationPercentage),
                    asset_depreciation_value: parseFloat(this.DepreciationValue),
                    notes: this.Note,
                };

                //Add
                this.$loading(true);
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : assetDepreciationData
                    }

                    assetDepreciationService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#assetDepreciationModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                // //Edit
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.AssetDepreciationId,
                        data : assetDepreciationData
                    }

                    assetDepreciationService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#assetDepreciationModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
